<template>
  <div class="zto-page-error">
    <div class="z-error-bg"><img :src="bgPic" alt="错误背景图"></div>
    <div class="z-error-body">
      <div class="z-error-pic"><img :src="errorPic" alt="错误图"></div>
    </div>
    <div class="z-error-footer">
      <h3 class="z-error-tip">{{errorMsg}}</h3>
      <el-button type="primary" @click="goSettingAuth()">
        <a>{{linkMsg}}</a>
      </el-button>
    </div>
  </div>
</template>
<script>
import bgPic from '@/assets/error/403.png'
import errorPic from '@/assets/error/dd403.png'
export default {
  name: 'noAuthority',
  data() {
    return {
      bgPic: bgPic,
      errorPic: errorPic,
      errorMsg: '权限不足，请联系管理员开通！',
      linkMsg: '返回',
      code: 403
    }
  },
  methods: {
    goSettingAuth() {
      this.$router.push('/')
    }
  }
}
</script>
